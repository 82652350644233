import { db } from '../../firebase';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
  setDoc,
  writeBatch,
  serverTimestamp,
  deleteField,
} from 'firebase/firestore';

/**
 * Resets all submissions for a form
 */
export const resetFormSubmissions = async (formId: string): Promise<void> => {
  try {
    console.log(`Resetting submissions for form ${formId}`);
    
    // Get batch for atomic operations
    const batch = writeBatch(db);
    
    // 1. Delete all submissions in form_submissions
    const submissionsQuery = query(
      collection(db, "form_submissions"),
      where("formId", "==", formId)
    );
    
    const querySnapshot = await getDocs(submissionsQuery);
    
    console.log(`Deleting ${querySnapshot.size} submissions from form_submissions`);
    
    querySnapshot.docs.forEach(doc => {
      batch.delete(doc.ref);
    });
    
    // 2. Also check and reset any form-specific submissions collection
    try {
      const formSubmissionsRef = collection(db, "forms", formId, "submissions");
      const formSubmissionsSnapshot = await getDocs(formSubmissionsRef);
      
      console.log(`Deleting ${formSubmissionsSnapshot.size} submissions from forms/${formId}/submissions`);
      
      formSubmissionsSnapshot.docs.forEach(doc => {
        batch.delete(doc.ref);
      });
    } catch (error) {
      console.log("No form-specific submissions collection or error accessing it:", error);
    }
    
    // 3. Reset formStats document if it exists
    const formStatsRef = doc(db, "formStats", formId);
    const formStatsDoc = await getDoc(formStatsRef);
    
    if (formStatsDoc.exists()) {
      console.log("Resetting formStats document");
      batch.update(formStatsRef, {
        responseCount: 0,
        submitterEmails: [],
        lastSubmission: null,
        resetAt: serverTimestamp()
      });
    } else {
      console.log("No formStats document exists, creating empty one");
      batch.set(formStatsRef, {
        formId,
        responseCount: 0,
        submitterEmails: [],
        lastSubmission: null,
        resetAt: serverTimestamp(),
        createdAt: serverTimestamp()
      });
    }
    
    // 4. Commit all the changes atomically
    await batch.commit();
    console.log(`Successfully reset all submissions for form ${formId}`);
  } catch (error) {
    console.error("Error resetting form submissions:", error);
    throw error;
  }
};

/**
 * Deletes a form and all its related data
 */
export const deleteForm = async (formId: string): Promise<void> => {
  try {
    console.log(`Starting deletion process for form ${formId}`);
    
    // Use batch for atomic operations
    const batch = writeBatch(db);
    
    // 1. Delete all submissions in form_submissions
    const submissionsQuery = query(
      collection(db, "form_submissions"),
      where("formId", "==", formId)
    );
    
    const submissionsSnapshot = await getDocs(submissionsQuery);
    console.log(`Deleting ${submissionsSnapshot.size} submissions from form_submissions`);
    
    submissionsSnapshot.docs.forEach(doc => {
      batch.delete(doc.ref);
    });
    
    // 2. Delete all submission details
    const detailsQuery = query(
      collection(db, "submission_details"),
      where("formId", "==", formId)
    );
    
    const detailsSnapshot = await getDocs(detailsQuery);
    console.log(`Deleting ${detailsSnapshot.size} submission details`);
    
    detailsSnapshot.docs.forEach(doc => {
      batch.delete(doc.ref);
    });
    
    // 3. Delete form-specific submissions collection if it exists
    try {
      const formSubmissionsRef = collection(db, "forms", formId, "submissions");
      const formSubmissionsSnapshot = await getDocs(formSubmissionsRef);
      
      console.log(`Deleting ${formSubmissionsSnapshot.size} submissions from forms/${formId}/submissions`);
      
      formSubmissionsSnapshot.docs.forEach(doc => {
        batch.delete(doc.ref);
      });
    } catch (error) {
      console.log("No form-specific submissions collection or error accessing it");
    }
    
    // 4. Delete formStats document
    const formStatsRef = doc(db, "formStats", formId);
    const formStatsDoc = await getDoc(formStatsRef);
    
    if (formStatsDoc.exists()) {
      console.log("Deleting formStats document");
      batch.delete(formStatsRef);
    }
    
    // 5. Finally, delete the form document itself
    const formRef = doc(db, "forms", formId);
    batch.delete(formRef);
    
    // 6. Commit all the deletes atomically
    await batch.commit();
    console.log(`Successfully deleted form ${formId} and all related data`);
  } catch (error) {
    console.error(`Error deleting form ${formId}:`, error);
    throw error;
  }
};

/**
 * Deletes a specific submission
 */
export const deleteSubmission = async (submissionId: string): Promise<void> => {
  try {
    console.log(`Deleting submission ${submissionId}`);
    
    // 1. Get the submission to check its formId
    const submissionRef = doc(db, "form_submissions", submissionId);
    const submissionDoc = await getDoc(submissionRef);
    
    if (!submissionDoc.exists()) {
      console.log(`Submission ${submissionId} not found`);
      return;
    }
    
    const submissionData = submissionDoc.data();
    const formId = submissionData.formId;
    const submissionEmail = submissionData.email;
    
    // Use batch for atomic operations
    const batch = writeBatch(db);
    
    // 2. Delete the submission
    batch.delete(submissionRef);
    
    // 3. Delete related submission details if they exist
    const detailsQuery = query(
      collection(db, "submission_details"),
      where("submissionId", "==", submissionId)
    );
    
    const detailsSnapshot = await getDocs(detailsQuery);
    detailsSnapshot.docs.forEach(doc => {
      batch.delete(doc.ref);
    });
    
    // 4. Update formStats document to decrement count
    const formStatsRef = doc(db, "formStats", formId);
    const formStatsDoc = await getDoc(formStatsRef);
    
    if (formStatsDoc.exists()) {
      const formStats = formStatsDoc.data();
      
      // Make sure we have a valid count
      const currentCount = typeof formStats.responseCount === 'number' 
        ? Math.max(0, formStats.responseCount - 1)
        : 0;
      
      // Remove email from submitterEmails array
      const updatedEmails = Array.isArray(formStats.submitterEmails)
        ? formStats.submitterEmails.filter((email: string) => email !== submissionEmail)
        : [];
      
      batch.update(formStatsRef, {
        responseCount: currentCount,
        submitterEmails: updatedEmails,
        updatedAt: serverTimestamp()
      });
    }
    
    // 5. Commit all changes
    await batch.commit();
    console.log(`Successfully deleted submission ${submissionId}`);
  } catch (error) {
    console.error(`Error deleting submission ${submissionId}:`, error);
    throw error;
  }
};

/**
 * Permanently removes the ghost form with ID "someUniqueId"
 */
export const removeGhostForm = async (): Promise<void> => {
  try {
    
    const ghostFormId = "someUniqueId";
    
    // First, try to delete the form itself
    try {
      await deleteDoc(doc(db, "forms", ghostFormId));
    } catch (err) {
      console.warn("Could not delete ghost form document:", err);
    }
    
    // Then delete any related formStats
    try {
      await deleteDoc(doc(db, "formStats", ghostFormId));
    } catch (err) {
      console.warn("Could not delete ghost form stats:", err);
    }
    
    // Delete all submissions for this form
    try {
      const submissionsQuery = query(
        collection(db, "form_submissions"),
        where("formId", "==", ghostFormId)
      );
      
      const submissionsSnapshot = await getDocs(submissionsQuery);
      for (const docSnap of submissionsSnapshot.docs) {
        await deleteDoc(docSnap.ref);
      }
    } catch (err) {
      console.warn("Could not delete ghost form submissions:", err);
    }
    
    // Delete all submission details
    try {
      const detailsQuery = query(
        collection(db, "submission_details"),
        where("formId", "==", ghostFormId)
      );
      
      const detailsSnapshot = await getDocs(detailsQuery);
      for (const docSnap of detailsSnapshot.docs) {
        await deleteDoc(docSnap.ref);
      }
    } catch (err) {
      console.warn("Could not delete ghost form submission details:", err);
    }
    
  } catch (error) {
    console.error("Error removing ghost form:", error);
  }
};

/**
 * Resets the form submission count without deleting submissions
 * This simply marks all current submissions with a resetDate
 */
export const resetFormSubmissionCounts = async (formId: string): Promise<void> => {
  try {
    console.log(`Resetting submission count for form ${formId} without deletion`);
    
    // Get current timestamp
    const resetTimestamp = serverTimestamp();
    
    // 1. Get all submissions for this form
    const submissionsQuery = query(
      collection(db, "form_submissions"),
      where("formId", "==", formId)
    );
    
    const querySnapshot = await getDocs(submissionsQuery);
    console.log(`Found ${querySnapshot.size} submissions to mark as reset`);
    
    if (querySnapshot.empty) {
      console.log("No submissions to reset");
      return;
    }
    
    // 2. Batch update all submissions with a reset date
    const batch = writeBatch(db);
    querySnapshot.docs.forEach(doc => {
      batch.update(doc.ref, {
        resetAt: resetTimestamp
      });
    });
    
    // 3. Also update the formStats document
    const formStatsRef = doc(db, "formStats", formId);
    const formStatsDoc = await getDoc(formStatsRef);
    
    if (formStatsDoc.exists()) {
      // Update the existing stats document
      batch.update(formStatsRef, {
        lastResetAt: resetTimestamp,
        responseCountBeforeReset: formStatsDoc.data().responseCount || 0,
        responseCount: 0 // Reset the count to zero
      });
    } else {
      // Create a new stats document if it doesn't exist
      batch.set(formStatsRef, {
        formId,
        lastResetAt: resetTimestamp,
        responseCountBeforeReset: 0,
        responseCount: 0,
        submitterEmails: [],
        resetAt: resetTimestamp,
        createdAt: resetTimestamp
      });
    }
    
    // 4. Commit all changes
    await batch.commit();
    console.log(`Successfully reset submission count for form ${formId}`);
  } catch (error) {
    console.error("Error resetting form submission counts:", error);
    throw error;
  }
};

/**
 * Update the removeAllFormResetStatus function to also reset the responseCount
 */
export const removeAllFormResetStatus = async (formId: string): Promise<number> => {
  try {
    // Get all submissions with reset status for this form
    const q = query(
      collection(db, "form_submissions"),
      where("formId", "==", formId),
      where("resetAt", "!=", null)
    );
    
    const querySnapshot = await getDocs(q);
    console.log(`Found ${querySnapshot.size} reset submissions to restore`);
    
    if (querySnapshot.empty) {
      return 0;
    }
    
    // Use batched writes for better performance
    const batch = writeBatch(db);
    let count = 0;
    
    querySnapshot.docs.forEach(doc => {
      batch.update(doc.ref, {
        resetAt: deleteField()
      });
      count++;
      
      // Firestore limits batch operations to 500
      if (count >= 500) {
        // Commit when we reach the limit and create a new batch
        batch.commit();
        const newBatch = writeBatch(db);
      }
    });
    
    // Only commit if we have operations to perform
    if (count > 0) {
      await batch.commit();
    }
    
    // Also update the formStats document to recalculate the response count
    const formStatsRef = doc(db, "formStats", formId);
    const formStatsDoc = await getDoc(formStatsRef);
    
    if (formStatsDoc.exists()) {
      // Get the total number of active (unarchived) submissions
      const activeSubmissionsQuery = query(
        collection(db, "form_submissions"),
        where("formId", "==", formId),
        where("archived", "!=", true)
      );
      const activeSubmissions = await getDocs(activeSubmissionsQuery);
      
      // Update the formStats with the new count and remove reset-related fields
      await updateDoc(formStatsRef, {
        lastResetAt: deleteField(),
        responseCountBeforeReset: deleteField(),
        responseCount: activeSubmissions.size // Update with actual count
      });
      
      console.log(`Updated formStats response count to ${activeSubmissions.size}`);
    }
    
    return count;
  } catch (error) {
    console.error(`Error removing reset status for form ${formId}:`, error);
    throw error;
  }
};

/**
 * Similarly, update the removeSubmissionResetStatus function to recalculate counts
 */
export const removeSubmissionResetStatus = async (submissionId: string): Promise<boolean> => {
  try {
    // First get the submission to find its formId
    const submissionRef = doc(db, "form_submissions", submissionId);
    const submissionDoc = await getDoc(submissionRef);
    
    if (!submissionDoc.exists()) {
      console.error(`Submission ${submissionId} not found`);
      return false;
    }
    
    const formId = submissionDoc.data().formId;
    
    // Remove the reset status
    await updateDoc(submissionRef, {
      resetAt: deleteField()  // Use deleteField() to remove the field completely
    });
    
    // Update the form stats if needed
    const formStatsRef = doc(db, "formStats", formId);
    const formStatsDoc = await getDoc(formStatsRef);
    
    if (formStatsDoc.exists()) {
      // If this is becoming an active submission, increase the count
      // Only if it's not archived
      if (submissionDoc.data().archived !== true) {
        const currentCount = formStatsDoc.data().responseCount || 0;
        await updateDoc(formStatsRef, {
          responseCount: currentCount + 1
        });
      }
    }
    
    return true;
  } catch (error) {
    console.error(`Error removing reset status for submission ${submissionId}:`, error);
    return false;
  }
};

/**
 * Admin function to permanently delete all archived submissions for a form
 * @param formId The ID of the form
 * @returns Promise resolving to the number of submissions deleted
 */
export const deleteAllArchivedSubmissions = async (formId: string): Promise<number> => {
  try {
    console.log(`Starting deletion of all archived submissions for form ${formId}`);
    
    // Query for all archived submissions for this form
    const archivedSubmissionsQuery = query(
      collection(db, "form_submissions"),
      where("formId", "==", formId),
      where("archived", "==", true)
    );
    
    const querySnapshot = await getDocs(archivedSubmissionsQuery);
    console.log(`Found ${querySnapshot.size} archived submissions to delete`);
    
    if (querySnapshot.empty) {
      return 0;
    }
    
    // Use batched writes for better performance
    const batch = writeBatch(db);
    let count = 0;
    let batchCount = 0;
    const maxBatchSize = 500; // Firestore batch size limit
    
    // Track all batches we need to commit
    const batches = [batch];
    
    // Process each archived submission
    for (const docSnapshot of querySnapshot.docs) {
      const submissionId = docSnapshot.id;
      const submissionData = docSnapshot.data();
      
      if (batchCount >= maxBatchSize) {
        // Create a new batch when we reach the limit
        const newBatch = writeBatch(db);
        batches.push(newBatch);
        batchCount = 0;
      }
      
      const currentBatch = batches[batches.length - 1];
      
      // Delete the submission
      currentBatch.delete(docSnapshot.ref);
      
      // Delete any related submission details documents
      try {
        const detailsQuery = query(
          collection(db, "submission_details"),
          where("submissionId", "==", submissionId)
        );
        
        const detailsSnapshot = await getDocs(detailsQuery);
        if (!detailsSnapshot.empty) {
          detailsSnapshot.forEach(detailDoc => {
            // Make sure we don't exceed batch size
            if (batchCount >= maxBatchSize) {
              const newerBatch = writeBatch(db);
              batches.push(newerBatch);
              batchCount = 0;
            }
            batches[batches.length - 1].delete(detailDoc.ref);
            batchCount++;
          });
        }
      } catch (error) {
        console.warn(`Error deleting details for submission ${submissionId}:`, error);
      }
      
      count++;
      batchCount++;
    }
    
    // Commit all batches
    for (const batchToCommit of batches) {
      await batchToCommit.commit();
    }
    
    console.log(`Successfully deleted ${count} archived submissions for form ${formId}`);
    
    // No need to update form stats since archived submissions aren't counted in statistics
    
    return count;
  } catch (error) {
    console.error(`Error deleting archived submissions for form ${formId}:`, error);
    throw error;
  }
};

/**
 * Admin function to permanently delete all archived submissions across all forms
 * @returns Promise resolving to the number of submissions deleted
 */
export const deleteAllArchivedSubmissionsGlobally = async (): Promise<number> => {
  try {
    console.log("Starting deletion of all archived submissions globally");
    
    // Query for all archived submissions
    const archivedSubmissionsQuery = query(
      collection(db, "form_submissions"),
      where("archived", "==", true)
    );
    
    const querySnapshot = await getDocs(archivedSubmissionsQuery);
    console.log(`Found ${querySnapshot.size} archived submissions to delete globally`);
    
    if (querySnapshot.empty) {
      return 0;
    }
    
    // Track forms that need stats recalculation
    const affectedForms = new Set<string>();
    
    // Use batched writes for better performance
    const batch = writeBatch(db);
    let count = 0;
    let batchCount = 0;
    const maxBatchSize = 500; // Firestore batch size limit
    
    // Track all batches we need to commit
    const batches = [batch];
    
    // Process each archived submission
    for (const docSnapshot of querySnapshot.docs) {
      const submissionId = docSnapshot.id;
      const submissionData = docSnapshot.data();
      
      // Track the form for recalculation if needed
      if (submissionData.formId) {
        affectedForms.add(submissionData.formId);
      }
      
      if (batchCount >= maxBatchSize) {
        // Create a new batch when we reach the limit
        const newBatch = writeBatch(db);
        batches.push(newBatch);
        batchCount = 0;
      }
      
      const currentBatch = batches[batches.length - 1];
      
      // Delete the submission
      currentBatch.delete(docSnapshot.ref);
      
      // Delete any related submission details documents
      try {
        const detailsQuery = query(
          collection(db, "submission_details"),
          where("submissionId", "==", submissionId)
        );
        
        const detailsSnapshot = await getDocs(detailsQuery);
        if (!detailsSnapshot.empty) {
          detailsSnapshot.forEach(detailDoc => {
            // Make sure we don't exceed batch size
            if (batchCount >= maxBatchSize) {
              const newerBatch = writeBatch(db);
              batches.push(newerBatch);
              batchCount = 0;
            }
            batches[batches.length - 1].delete(detailDoc.ref);
            batchCount++;
          });
        }
      } catch (error) {
        console.warn(`Error deleting details for submission ${submissionId}:`, error);
      }
      
      count++;
      batchCount++;
    }
    
    // Commit all batches
    for (const batchToCommit of batches) {
      await batchToCommit.commit();
    }
    
    console.log(`Successfully deleted ${count} archived submissions globally`);
    
    return count;
  } catch (error) {
    console.error("Error deleting archived submissions globally:", error);
    throw error;
  }
};