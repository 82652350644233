import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, getDocs, query, where, doc, deleteDoc, getDoc, orderBy, updateDoc, setDoc, increment, limit } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import {
  Typography, Button, TextField, IconButton, 
  Box, Dialog, DialogTitle, DialogContent, DialogActions, Divider, Paper,
  Grid, Card, CardContent, CardActions, InputAdornment, Chip, FormControl,
  InputLabel, Select, SelectChangeEvent, MenuItem, ThemeProvider, createTheme, alpha, DialogContentText, List, ListItem, ListItemButton, ListItemText
} from "@mui/material";
import {
  Delete, Edit, Visibility, Add, ExitToApp, 
  AccountCircle, Share, ContentCopy, Search,
  DateRange, BookmarkBorder, Close, BarChart, GridView as GridViewIcon, ViewList as ViewListIcon
} from "@mui/icons-material";
import { useUser } from "@clerk/clerk-react";
import {
  TwitterShareButton, FacebookShareButton, 
  LinkedinShareButton, EmailShareButton,
  TwitterIcon, FacebookIcon, LinkedinIcon, EmailIcon
} from "react-share";
import { format } from "date-fns";
import SharedNavBar from "../components/SharedNavBar";
import SubmissionsHistoryModal from '../components/SubmissionsHistoryModal';
import { getFormSubmissions } from '../services/firebase/submissionReader';
import { resetFormSubmissions, deleteForm, removeGhostForm as importedRemoveGhostForm, resetFormSubmissionCounts, deleteAllArchivedSubmissionsGlobally } from '../services/firebase/submissionDeleter';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DuplicateFormDialog from '../components/DuplicateFormDialog';
import { useUserPreferences } from '../hooks/useUserPreferences';
import { themeOptions, createAppTheme } from '../theme/themeOptions';

const Home: React.FC = () => {
  
  // With the preference hook:
  const { theme: themeColor, viewMode, setTheme, setViewMode } = useUserPreferences();
  
  // Other state variables remain unchanged
  const [forms, setForms] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [userRole, setUserRole] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { user } = useUser();
  
  // Share dialog state
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [shareFormId, setShareFormId] = useState<string | null>(null);
  const [shareFormTitle, setShareFormTitle] = useState<string>("");
  const [copySuccess, setCopySuccess] = useState<{link: boolean, embed: boolean}>({
    link: false,
    embed: false
  });
  const [shareFormSlug, setShareFormSlug] = useState<string | null>(null);
  const [shareCompanySlug, setShareCompanySlug] = useState<string | null>(null);
  
  // Theme customization state
  const [themeDialogOpen, setThemeDialogOpen] = useState(false);
  
  // Create theme based on selected color
  const selectedTheme = themeOptions.find(theme => theme.name === themeColor) || themeOptions[0];
  const theme = createAppTheme(themeColor);

  // Save theme preference when changed
  const handleThemeChange = (event: SelectChangeEvent) => {
    const newTheme = event.target.value;
    setTheme(newTheme);
  };

  // Fetch user role from Firestore
  useEffect(() => {
    const fetchUserRole = async () => {
      if (!user?.id) return;
      try {
        const userRef = doc(db, "users", user.id);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          setUserRole(userSnap.data().role);
        } else {
          console.warn("No role found for user in Firestore.");
        }
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
    };

    fetchUserRole();
  }, [user]);

  // Fetch forms based on user role
  useEffect(() => {
    if (!user) return;
    
    // Remove ghost form and fetch actual forms
    const initializeData = async () => {
      try {
        // Try to remove the ghost form first
        await importedRemoveGhostForm();
      } catch (error) {
        console.error("Error removing ghost form:", error);
      }
      
      // Then fetch actual forms
      fetchForms();
    };
    
    initializeData();
  }, [user, userRole]);

  // Add this useEffect to refresh data when navigating from form submission:
  useEffect(() => {
    // Check if we've navigated here from a form submission
    const fromSubmission = sessionStorage.getItem('formSubmitted');
    if (fromSubmission === 'true') {
      // Clear the flag
      sessionStorage.removeItem('formSubmitted');
      
      // Force refresh of form data
      if (user) {
        fetchForms();
      }
    }
  }, []);

  // Move the fetchForms function outside the useEffect so we can call it independently
  // Update the fetchForms function to use the new submission counting approach
// Replace the current fetchForms function with this improved version that uses client-side filtering
// Update the fetchForms function to also check responseCount from formStats
// Replace the fetchForms function with this updated version:
// Replace the fetchForms function with this optimized version
const fetchForms = React.useCallback(async () => {
  setIsLoading(true);
  try {
    // Query forms based on user role with pagination and proper ordering
    let q;
    const formsPerPage = 50; // Increased limit for better user experience
    
    if (userRole === "Admin" || userRole === "Manager") {
      q = query(
        collection(db, "forms"),
        orderBy("updatedAt", "desc"),
        limit(formsPerPage)
      );
    } else {
      q = query(
        collection(db, "forms"), 
        where("userId", "==", user?.id),
        orderBy("updatedAt", "desc"), 
        limit(formsPerPage)
      );
    }

    const querySnapshot = await getDocs(q);
    const formList = querySnapshot.docs
      .map((doc) => ({ 
        id: doc.id, 
        ...(doc.data() as any)
      }))
      .filter(form => form.id !== "someUniqueId");

    // Process forms in batches to avoid UI freezing
    const batchSize = 10;
    const batches = [];
    
    for (let i = 0; i < formList.length; i += batchSize) {
      batches.push(formList.slice(i, i + batchSize));
    }
    
    // Define interfaces for form data types
    interface FormSubmissionStats {
      responseCount?: number;
      lastResetAt?: Date | null;
      updatedAt?: Date | null;
    }
    
    interface ProcessedForm {
      id: string;
      formTitle?: string;
      title?: string;
      description?: string;
      userId: string;
      email?: string;
      createdAt: Date | any;
      updatedAt: Date | any;
      responses: number;
      totalResponses: number;
      hasResetDate: boolean;
      lastUpdated?: Date | any;
    }
    
    let processedForms: ProcessedForm[] = [];
    
    for (const batch of batches) {
      const batchResults = await Promise.all(batch.map(processFormWithStats));
      processedForms = [...processedForms, ...batchResults];
      
      // Update state incrementally to show progress
      if (processedForms.length > 0) {
        setForms(prev => {
          // Create a map of existing forms by id for quick lookup
          const existingFormsMap = new Map(prev.map(form => [form.id, form]));
          
          // Merge new forms, preferring new data but preserving existing data if needed
          const merged = processedForms.map(newForm => {
            const existing = existingFormsMap.get(newForm.id);
            return existing ? { ...existing, ...newForm } : newForm;
          });
          
          return merged;
        });
      }
    }
  } catch (error) {
    console.error("Error fetching forms:", error);
  } finally {
    setIsLoading(false);
  }
}, [user?.id, userRole]);

// Extract the form processing logic to a separate function for better organization
const processFormWithStats = async (form: any) => {
  try {
    // Get submission counts using a more efficient query with limit
    const submissionsRef = collection(db, "form_submissions");
    const submissionsQuery = query(
      submissionsRef,
      where("formId", "==", form.id),
      limit(1000) // Limit to prevent excessive reads
    );
    
    const submissionsSnapshot = await getDocs(submissionsQuery);
    
    // Count active submissions as those that are NOT archived AND NOT reset
    let activeCount = 0;
    let totalSubmissions = submissionsSnapshot.size;
    
    submissionsSnapshot.docs.forEach(doc => {
      const data = doc.data();
      // Count as active if it's not archived AND not reset
      if (data.archived !== true && !data.resetAt) {
        activeCount++;
      }
    });
    
    // Try to get formStats (for other metadata) but don't fail if unavailable
    let formStats = null;
    try {
      const statsRef = doc(db, "formStats", form.id);
      const statsDoc = await getDoc(statsRef);
      if (statsDoc.exists()) {
        formStats = statsDoc.data();
        
        // Update formStats if it doesn't match our count
        if (formStats.responseCount !== activeCount) {
          await updateDoc(statsRef, {
            responseCount: activeCount,
            updatedAt: serverTimestamp()
          });
        }
      }
    } catch (statsError) {
      // Silent error handling for stats
    }
    
    return {
      ...form,
      responses: activeCount,
      totalResponses: totalSubmissions,
      hasResetDate: !!(formStats?.lastResetAt),
      lastUpdated: formStats?.updatedAt || form.updatedAt
    };
  } catch (error) {
    // Handle errors gracefully
    return {
      ...form,
      responses: 0, 
      totalResponses: 0
    };
  }
};

  // Handle Form Deletion
  const handleDeleteForm = async (formId: string, formTitle: string) => {
    // Confirm deletion with the user
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the form "${formTitle || 'Untitled Form'}"? This will delete all submissions and cannot be undone.`
    );
    
    if (!confirmDelete) return;
    
    try {
      setIsLoading(true);
      
      // Delete the form and all related data
      await deleteForm(formId);
      
      // Update local state
      setForms(prevForms => prevForms.filter(form => form.id !== formId));
      
      // Show success message
      alert("Form deleted successfully!");
    } catch (error) {
      console.error("Error deleting form:", error);
      alert("Error deleting form. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle Menu Close
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Inside your Home component, add this state:
  const [selectedUserEmail, setSelectedUserEmail] = useState("");

  // Create a list of unique user emails based on your forms collection.
  const userEmails = React.useMemo(() => {
    const emails = forms.map(form => form.email).filter(Boolean);
    return Array.from(new Set(emails)).sort();
  }, [forms]);

  // Add these states for user filtering
  const [users, setUsers] = useState<Array<{id: string, email: string, name: string}>>([]);
  const [selectedUserId, setSelectedUserId] = useState("");

  // Add this function to fetch all users (for admin filtering)
  const fetchUsers = async () => {
    if (userRole !== "Admin") return;
    
    try {
      const usersSnapshot = await getDocs(collection(db, "users"));
      const usersData = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        email: doc.data().email || "No email",
        name: doc.data().firstName && doc.data().lastName ? 
          `${doc.data().firstName} ${doc.data().lastName}` : 
          doc.data().firstName || doc.data().email || doc.id
      }));
      
      // Sort users alphabetically by name
      usersData.sort((a, b) => a.name.localeCompare(b.name));
      setUsers(usersData);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // Update your useEffect to fetch users when role is confirmed
  useEffect(() => {
    if (userRole === "Admin") {
      fetchUsers();
    }
  }, [userRole]);

  // Update filteredForms with proper dependencies
const filteredForms = React.useMemo(() => {
  let filtered = forms;
  
  if (userRole === "Admin" && selectedUserId) {
    filtered = filtered.filter(form => form.userId === selectedUserId);
  }
  
  if (searchQuery) {
    const lowerQuery = searchQuery.toLowerCase();
    filtered = filtered.filter(form => {
      const title = (form.formTitle || form.title || "").toLowerCase();
      const description = (form.description || "").toLowerCase();
      return title.includes(lowerQuery) || description.includes(lowerQuery);
    });
  }
  
  return filtered;
}, [forms, searchQuery, selectedUserId, userRole]);

  // Share dialog functions
  const handleOpenShareDialog = async (formId: string, formTitle: string) => {
    setShareFormId(formId);
    setShareFormTitle(formTitle || "Untitled Form");
    setShareCompanySlug(null);
    setShareFormSlug(null);
    setShareDialogOpen(true);
    setCopySuccess({ link: false, embed: false });
    
    try {
      // Get form details and creator
      const formDoc = await getDoc(doc(db, "forms", formId));
      
      if (formDoc.exists()) {
        const formData = formDoc.data();
        const formCreatorId = formData.userId;
        const formSlug = formData.formSlug;
        
        if (formSlug) {
          setShareFormSlug(formSlug);
          
          // Try short company name first (preferred)
          const creatorDoc = await getDoc(doc(db, "users", formCreatorId));
          if (creatorDoc.exists()) {
            const creatorData = creatorDoc.data();
            if (creatorData.shortCompanyName) {
              setShareCompanySlug(creatorData.shortCompanyName);
              return;
            }
          }
          
          // Fall back to landing page slug
          const creatorLandingPageDoc = await getDoc(doc(db, "landing_pages", formCreatorId));
          if (creatorLandingPageDoc.exists()) {
            const landingPageData = creatorLandingPageDoc.data();
            if (landingPageData.companySlug) {
              setShareCompanySlug(landingPageData.companySlug);
            }
          }
        }
      }
    } catch (error) {
      console.error("Error fetching form slug information:", error);
    }
  };

  const handleCloseShareDialog = () => {
    setShareDialogOpen(false);
    setShareFormId(null);
  };

  const handleCopyLink = (type: 'link' | 'embed') => {
    if (!shareFormId) return;
    
    const textToCopy = type === 'link' 
      ? getShareUrl()
      : getEmbedCode();
      
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setCopySuccess({ link: type === 'link', embed: type === 'embed' });
        setTimeout(() => setCopySuccess({ link: false, embed: false }), 3000);
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  };

  // Create a proper absolute URL for sharing
  const getShareUrl = () => {
    const baseUrl = window.location.origin;
    
    // Return slug-based URL if both slugs are available
    if (shareCompanySlug && shareFormSlug) {
      return `${baseUrl}/${shareCompanySlug}/${shareFormSlug}`;
    }
    
    // Otherwise fall back to form ID
    return `${baseUrl}/fill/${shareFormId}`;
  };

  // Update getEmbedCode to use the shared URL function
  const getEmbedCode = () => {
    const shareUrl = getShareUrl();
    return `<iframe src="${shareUrl}" width="100%" height="600" style="border: none; border-radius: 4px; box-shadow: 0 2px 10px rgba(0,0,0,0.1);" title="${shareFormTitle}"></iframe>`;
  };

  // Add new state variable for reset dialog
  const [resetCountDialogOpen, setResetCountDialogOpen] = useState(false);
  const [selectedFormForReset, setSelectedFormForReset] = useState<string | null>(null);
  const [selectedFormTitle, setSelectedFormTitle] = useState<string>("");

  // Replace the existing handleResetResponseCount function:
const handleResetResponseCount = async () => {
  if (!selectedFormForReset) {
    setResetCountDialogOpen(false);
    return;
  }

  try {
    // Use our new function to reset counts without deleting
    await resetFormSubmissionCounts(selectedFormForReset);
    
    // Update the local state to reflect the reset
    setForms(prevForms => 
      prevForms.map(form => 
        form.id === selectedFormForReset ? { ...form, responses: 0 } : form
      )
    );
    
    // Close dialogs
    setResetCountDialogOpen(false);
    setSubmissionsHistoryOpen(false);
    
    // Show confirmation
    alert("Response count has been reset successfully!");
  } catch (error) {
    console.error("Error resetting response count:", error);
    alert("Failed to reset response count. Please try again.");
  }
};
  
  // Add state for tab value
  const [tabValue, setTabValue] = useState(0);

  // Update handler for opening reset dialog to work with any response count
  const [submissionsHistoryOpen, setSubmissionsHistoryOpen] = useState(false);
  const [submissionsHistory, setSubmissionsHistory] = useState<any[]>([]);
  const [loadingSubmissions, setLoadingSubmissions] = useState(false);

  // Add a function to fetch submissions history:
  const fetchSubmissionsHistory = async (formId: string) => {
    setLoadingSubmissions(true);
    
    try {
      // First try to get submission records directly
      const submissionsRef = collection(db, "form_submissions");
      const q = query(
        submissionsRef,
        where("formId", "==", formId),
        orderBy("submittedAt", "desc")
      );
      
      const querySnapshot = await getDocs(q);
      
      // Process the submissions, ensuring archive field and reset field consistency
      const historyData = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          email: data.email || "No email provided",
          submittedAt: data.submittedAt,
          // Explicitly set archived to false if it doesn't exist
          archived: data.archived === true ? true : false,
          archivedAt: data.archivedAt || null,
          // Include reset information
          resetAt: data.resetAt || null
        };
      });
      
      setSubmissionsHistory(historyData);
      
    } catch (error) {
      console.error("Error fetching submissions history:", error);
      setSubmissionsHistory([]);
    } finally {
      setLoadingSubmissions(false);
    }
  };

  // Update your handleResponsesChipClick function to avoid opening both dialogs:
  const handleResponsesChipClick = (formId: string, formTitle: string, responseCount: number) => {
    // Navigate to the submissions history page instead of opening the modal
    navigate(`/forms/${formId}/submissions`);
  };

  // Add code to handle closing the submissions history modal
  const handleCloseSubmissionsHistory = () => {
    setSubmissionsHistoryOpen(false);
    setSubmissionsHistory([]);
  };

  // Add these state variables to your Home component
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [selectedFormForDuplicate, setSelectedFormForDuplicate] = useState<string | null>(null);
  const [selectedFormEmail, setSelectedFormEmail] = useState("");

  // Add a handler function for opening the duplicate dialog
  const handleDuplicateForm = (formId: string, formTitle: string, email: string) => {
    setSelectedFormForDuplicate(formId);
    setSelectedFormTitle(formTitle || "Untitled Form");
    setSelectedFormEmail(email || "");
    setDuplicateDialogOpen(true);
  };

  // Add a success handler for form duplication
  const handleDuplicateSuccess = (newFormId: string) => {
    // Show success message
    alert("Form duplicated successfully!");
    
    // Refresh the forms list
    fetchForms();
  };

  // Add a function to toggle between view modes
  const toggleViewMode = () => {
    setViewMode(viewMode === 'grid' ? 'list' : 'grid');
  };

  // Format date helper function to improve date rendering
  const formatFormDate = (dateValue: any, formId?: string): string => {
    try {
      // First check if this is a newly created form in this session
      if (formId) {
        const justCreated = sessionStorage.getItem(`form_created_${formId}`);
        
        if (justCreated) {
          return new Intl.DateTimeFormat('en-US', {
            month: 'short', day: 'numeric', year: 'numeric'
          }).format(new Date(justCreated));
        }
      }
      
      // Handle Firestore timestamp
      if (dateValue && typeof dateValue === 'object') {
        // Firestore timestamp with toDate method
        if (typeof dateValue.toDate === 'function') {
          return new Intl.DateTimeFormat('en-US', {
            month: 'short', day: 'numeric', year: 'numeric'
          }).format(dateValue.toDate());
        }
        
        // Firestore timestamp with seconds
        if (dateValue.seconds !== undefined) {
          return new Intl.DateTimeFormat('en-US', {
            month: 'short', day: 'numeric', year: 'numeric'
          }).format(new Date(dateValue.seconds * 1000));
        }
        
        // Server timestamp placeholder
        if (dateValue._methodName === "serverTimestamp") {
          return "Recently created";
        }
      }
      
      // Handle string or Date object
      if (dateValue instanceof Date || typeof dateValue === 'string') {
        const date = new Date(dateValue);
        if (!isNaN(date.getTime())) {
          return new Intl.DateTimeFormat('en-US', {
            month: 'short', day: 'numeric', year: 'numeric'
          }).format(date);
        }
      }
      
      return "No date";
    } catch (error) {
      console.error("Error formatting date:", error, dateValue);
      return "Date error";
    }
  };

  // Add this function to your Home component
  const handleOpenForm = (formId: string) => {
    navigate(`/edit/${formId}`);
  };

  // Add state variables for the global delete dialog
  const [globalDeleteDialogOpen, setGlobalDeleteDialogOpen] = useState(false);
  const [deletingGlobalArchived, setDeletingGlobalArchived] = useState(false);

  // Add the handler function for deleting all archived submissions globally
  const handleDeleteAllArchivedGlobally = async () => {
    if (deletingGlobalArchived) return;
    
    try {
      setDeletingGlobalArchived(true);
      
      const count = await deleteAllArchivedSubmissionsGlobally();
      
      // Close the dialog
      setGlobalDeleteDialogOpen(false);
      
      // Refresh forms to update counts
      fetchForms();
      
      // Show success message
      alert(`Successfully deleted ${count} archived submissions across all forms.`);
    } catch (error) {
      console.error("Error deleting archived submissions globally:", error);
      alert("Failed to delete archived submissions. Please try again.");
    } finally {
      setDeletingGlobalArchived(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ 
        backgroundColor: theme.palette.background.default, 
        minHeight: '100vh',
        pb: 8 
      }}>
        {/* Use SharedNavBar component */}
        <SharedNavBar onThemeClick={() => setThemeDialogOpen(true)} />

        {/* Content - Fixed layout */}
        <Box sx={{ pt: 4, px: { xs: 2, md: 4 }, maxWidth: 'lg', mx: 'auto' }}>
          {/* Welcome section */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h4" fontWeight="700" gutterBottom>
              Welcome back, {user?.firstName || 'User'}
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, color: 'text.secondary', maxWidth: 600 }}>
              Manage your forms, track responses, and create new surveys with our intuitive form builder.
            </Typography>
          </Box>

          {/* Action and search row */}
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-between',
              alignItems: { xs: 'stretch', md: 'center' },
              mb: 4,
              gap: 2
            }}
          >
            {/* Create new form button */}
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => navigate("/new-form")}
              sx={{ py: 1.2, fontWeight: 600 }}
            >
              Create New Form
            </Button>

            {/* Right side controls */}
            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', justifyContent: 'flex-end' }}>
              {/* View toggle button */}
              <Button 
                variant="outlined"
                color="primary"
                onClick={toggleViewMode}
                startIcon={viewMode === 'grid' ? <ViewListIcon /> : <GridViewIcon />}
                sx={{ height: 40 }}
              >
                {viewMode === 'grid' ? 'List View' : 'Grid View'}
              </Button>

              {/* Search bar */}
              <Paper 
                sx={{ 
                  p: 0.5, 
                  display: 'flex', 
                  alignItems: 'center', 
                  width: { xs: '100%', md: '300px' },
                  borderRadius: 2
                }}
              >
                <TextField
                  fullWidth
                  placeholder="Search your forms..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search color="action" sx={{ ml: 1 }} />
                      </InputAdornment>
                    ),
                    endAdornment: searchQuery ? (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => setSearchQuery('')}
                        >
                          <Close fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                    sx: { px: 1 }
                  }}
                />
              </Paper>

              {/* Admin-only dropdown for filtering by user email */}
              {userRole === "Admin" && (
                <FormControl sx={{ minWidth: 220 }} size="small">
                  <InputLabel id="user-filter-label">Filter by User</InputLabel>
                  <Select
                    labelId="user-filter-label"
                    value={selectedUserId}
                    label="Filter by User"
                    onChange={(e: SelectChangeEvent<string>) => setSelectedUserId(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>All Users</em>
                    </MenuItem>
                    {users.map(user => (
                      <MenuItem key={user.id} value={user.id}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography variant="body2">{user.name}</Typography>
                          <Typography variant="caption" color="text.secondary">
                            {user.email}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
          </Box>
          
          {/* Forms Grid Layout */}
          {isLoading ? (
            <Box sx={{ textAlign: 'center', py: 8 }}>
              <Typography>Loading your forms...</Typography>
            </Box>
          ) : filteredForms.length === 0 ? (
            <Paper sx={{ p: 4, textAlign: 'center', borderRadius: '16px', mt: 4 }}>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                No forms found
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {searchQuery 
                  ? "Try a different search term or clear your search" 
                  : "Create your first form to get started"}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={() => navigate("/new-form")}
                sx={{ mt: 3 }}
              >
                Create New Form
              </Button>
            </Paper>
          ) : viewMode === 'grid' ? (
            // Grid View - More compact cards
            <Grid container spacing={2}>
              {filteredForms.map((form) => (
                <Grid item xs={12} sm={6} md={4} key={form.id}>
                  <Paper
                    sx={{
                      borderRadius: '12px',
                      overflow: 'hidden',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      transition: 'transform 0.2s, box-shadow 0.2s',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: 3
                      }
                    }}
                    elevation={1}
                  >
                    <Box
                      sx={{
                        p: 2,
                        flexGrow: 1,
                        cursor: 'pointer',
                        transition: 'background-color 0.2s',
                        '&:hover': {
                          backgroundColor: alpha(theme.palette.primary.main, 0.05)
                        }
                      }}
                      onClick={() => handleOpenForm(form.id)}
                    >
                      <Typography 
                        variant="h6" 
                        noWrap 
                        title={form.formTitle || form.title || "Untitled Form"}
                        sx={{ mb: 1 }}
                      >
                        {form.formTitle || form.title || "Untitled Form"}
                      </Typography>
                      
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          mb: 2,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical'
                        }}
                      >
                        {form.description || "No description"}
                      </Typography>
                      
                      <Box 
                        sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          justifyContent: 'space-between',
                          mt: 'auto'
                        }}
                      >
                        <Chip 
                          label={`${form.responses || 0} responses`}
                          size="small" 
                          color={form.responses ? 'primary' : 'default'}
                          variant="outlined"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleResponsesChipClick(form.id, form.formTitle || "Untitled Form", form.responses || 0);
                          }}
                          sx={{ 
                            cursor: 'pointer',
                            '&:hover': { 
                              bgcolor: alpha(theme.palette.primary.main, 0.1),
                              borderColor: theme.palette.primary.main
                            }
                          }}
                        />
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <DateRange fontSize="small" sx={{ mr: 0.5, color: 'text.secondary', fontSize: '0.9rem' }} />
                          <Typography variant="caption" color="text.secondary">
                            {formatFormDate(form.createdAt, form.id)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    
                    <Divider />
                    
                    <Box 
                      sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                        p: 1,
                        bgcolor: alpha(theme.palette.background.default, 0.7)
                      }}
                    >
                      <Box>
                        <IconButton 
                          size="small"
                          onClick={() => handleOpenForm(form.id)}
                          title="Edit Form"
                          color="primary"
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                        <IconButton 
                          size="small"
                          onClick={() => navigate(`/fill/${form.id}`)}
                          title="Preview Form"
                          color="secondary"
                        >
                          <Visibility fontSize="small" />
                        </IconButton>
                        <IconButton 
                          size="small"
                          onClick={() => handleOpenShareDialog(form.id, form.formTitle || form.title || "")}
                          title="Share Form"
                          color="primary"
                        >
                          <Share fontSize="small" />
                        </IconButton>
                        {/* Add Reports Button */}
                        <IconButton 
                          size="small"
                          onClick={() => navigate(`/responses/${form.id}`)}
                          title="View Response Reports"
                          color="info"
                          disabled={!form.responses || form.responses === 0}
                        >
                          <BarChart fontSize="small" />
                        </IconButton>
                      </Box>
                      <Box>
                        <IconButton 
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDuplicateForm(form.id, form.formTitle || form.title || "", form.email || "");
                          }}
                          title="Duplicate Form"
                          sx={{ color: '#9c27b0' }}
                        >
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                        {(userRole === "Admin" || userRole === "Manager" || form.userId === user?.id) && (
                          <IconButton 
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteForm(form.id, form.formTitle || form.title || "");
                            }}
                            title="Delete Form"
                            color="error"
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          ) : (
            // List View - More efficient for many forms
            <Paper sx={{ overflow: 'hidden', borderRadius: '12px' }}>
              <List disablePadding>
                {filteredForms.map((form, index) => (
                  <React.Fragment key={form.id}>
                    {index > 0 && <Divider component="li" />}
                    <ListItem 
                      disablePadding
                      sx={{ 
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: 'stretch',
                        p: { xs: 2, sm: 2 }, // Add padding directly to ListItem
                        '&:hover': { bgcolor: alpha(theme.palette.primary.main, 0.03) } // Lighter hover effect
                      }}
                    >
                      {/* Main form info - no longer a button */}
                      <Box 
                        sx={{ 
                          flexGrow: 1,
                          width: '100%',
                          pr: { xs: 1, sm: 12 }  // Reduced padding for action buttons
                        }}
                      >
                        <Box 
                          sx={{ 
                            display: 'flex', 
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: { xs: 'flex-start', sm: 'center' },
                            gap: 1,
                            mb: 0.5
                          }}
                        >
                          <Typography variant="subtitle1" fontWeight="medium">
                            {form.formTitle || "Untitled Form"}
                          </Typography>
                          <Chip 
                            label={`${form.responses || 0} responses`}
                            size="small" 
                            color={form.responses ? 'primary' : 'default'}
                            variant="outlined"
                            onClick={(e) => {
                              handleResponsesChipClick(form.id, form.formTitle || "Untitled Form", form.responses || 0);
                            }}
                            sx={{ 
                              cursor: 'pointer',
                              alignSelf: { xs: 'flex-start', sm: 'center' },
                              '&:hover': { 
                                bgcolor: alpha(theme.palette.primary.main, 0.1),  // Distinct hover for chip
                                borderColor: theme.palette.primary.main
                              }
                            }}
                          />
                        </Box>
                        
                        <Typography 
                          variant="caption" 
                          color="text.secondary" 
                          sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            flexWrap: 'wrap',
                            mt: 0.5 
                          }}
                        >
                          <DateRange fontSize="small" sx={{ mr: 0.5, fontSize: '0.8rem' }} />
                          {formatFormDate(form.createdAt, form.id)}
                          {form.userId !== user?.id && (
                            <Chip 
                              label="Shared"
                              size="small"
                              variant="outlined"
                              sx={{ ml: 1, height: 20, fontSize: '0.6rem' }}
                            />
                          )}
                        </Typography>
                      </Box>
                      
                      {/* Action buttons for mobile and desktop */}
                      <Box 
                        sx={{ 
                          display: 'flex',
                          width: { xs: '100%', sm: 'auto' },
                          justifyContent: { xs: 'flex-start', sm: 'flex-end' },
                          p: { xs: 1, sm: 0 },
                          position: { xs: 'static', sm: 'absolute' },
                          right: { sm: 8 },
                          gap: 0.5,
                          '& .MuiIconButton-root': {
                            padding: '8px',
                            '&:hover': {
                              bgcolor: alpha(theme.palette.primary.main, 0.1) // Distinct hover for icons
                            }
                          }
                        }}
                      >
                        <IconButton 
                          color="primary" 
                          onClick={() => navigate(`/edit/${form.id}`)}
                          size="small"
                          title="Edit Form"
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                        
                        <IconButton 
                          color="secondary"
                          onClick={() => navigate(`/fill/${form.id}`)}
                          size="small"
                          title="Preview Form"
                        >
                          <Visibility fontSize="small" />
                        </IconButton>
                        
                        <IconButton 
                          color="primary"
                          onClick={() => handleOpenShareDialog(form.id, form.formTitle)}
                          size="small"
                          title="Share Form"
                        >
                          <Share fontSize="small" />
                        </IconButton>

                        <IconButton 
                          color="info"
                          onClick={() => navigate(`/responses/${form.id}`)}
                          size="small"
                          title="View Responses"
                          disabled={!form.responses || form.responses === 0}
                        >
                          <BarChart fontSize="small" />
                        </IconButton>

                        <IconButton 
                          onClick={() => handleDuplicateForm(form.id, form.formTitle, form.email)}
                          size="small"
                          title="Duplicate Form"
                          sx={{ color: '#9c27b0' }}
                        >
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      
                        {(userRole === "Admin" || userRole === "Manager" || form.userId === user?.id) && (
                          <IconButton 
                            color="error"
                            onClick={() => handleDeleteForm(form.id, form.formTitle)}
                            size="small"
                            title="Delete Form"
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        )}
                      </Box>
                    </ListItem>
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          )}

          {/* Add floating action button for creating new form */}
          <Box
            sx={{
              position: 'fixed',
              bottom: 20,
              right: 20,
            }}
          >
            <Button
              variant="contained"
              color="primary" // Changed from "secondary" to "primary" to match theme
              sx={{ 
                borderRadius: '50%', 
                width: 64,
                height: 64,
                boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                  transform: 'scale(1.05)',
                },
                transition: 'transform 0.2s'
              }}
              onClick={() => navigate("/new-form")}
              title="Create New Form"
            >
              <Add sx={{ fontSize: 32 }} />
            </Button>
          </Box>

          {userRole === "Admin" && (
            <Box sx={{ mt: 3, textAlign: 'center', display: 'flex', justifyContent: 'center', gap: 2 }}>
              <Button 
                variant="outlined" 
                color="warning"
                onClick={async () => {
                  try {
                    await importedRemoveGhostForm();
                    alert("Ghost form cleanup attempted. Please refresh the page.");
                  } catch (error) {
                    console.error("Error in manual ghost form cleanup:", error);
                    alert("Error removing ghost form. Check console for details.");
                  }
                }}
                startIcon={<Delete />}
                size="small"
              >
                Remove Ghost Form
              </Button>
              
              <Button 
                variant="outlined" 
                color="error"
                onClick={() => setGlobalDeleteDialogOpen(true)}
                startIcon={<Delete />}
                size="small"
              >
                Delete All Archived Submissions
              </Button>
            </Box>
          )}
        </Box>

        {/* Share Form Dialog */}
        <Dialog 
          open={shareDialogOpen} 
          onClose={handleCloseShareDialog}
          PaperProps={{
            sx: { borderRadius: '12px', maxWidth: 450 }
          }}
        >
          <DialogTitle sx={{ pb: 1 }}>
            <Typography variant="h6" fontWeight="600">Share Form</Typography>
            <Typography variant="body2" color="text.secondary">
              {shareFormTitle}
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ pt: 2 }}>
            {/* Direct link section */}
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 600 }}>
                Form URL
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={shareFormId ? getShareUrl() : ''}
                InputProps={{
                  readOnly: true,
                  sx: { borderRadius: '8px' }
                }}
              />
              
              <Button
                startIcon={<ContentCopy />}
                onClick={() => handleCopyLink('link')}
                sx={{ mt: 1.5 }}
                color={copySuccess.link ? "success" : "primary"}
                variant={copySuccess.link ? "contained" : "outlined"}
              >
                {copySuccess.link ? "Copied!" : "Copy to clipboard"}
              </Button>
              
              {/* Add this message about URL format */}
              {shareCompanySlug && shareFormSlug ? (
                <Typography variant="caption" display="block" sx={{ mt: 1, color: 'success.main' }}>
                  Using custom URL format with your company and form name
                </Typography>
              ) : (
                <Typography variant="caption" display="block" sx={{ mt: 1, color: 'text.secondary' }}>
                  Using standard form URL format. Set up a company profile and form slug to get a custom URL.
                </Typography>
              )}
            </Box>
            
            {/* Embed Code Section */}
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 600 }}>
                Embed on your website
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                multiline
                rows={3}
                value={shareFormId ? getEmbedCode() : ''}
                InputProps={{
                  readOnly: true,
                  sx: { 
                    fontFamily: 'monospace',
                    fontSize: '0.8rem',
                    borderRadius: '8px'
                  }
                }}
              />
              
              <Button
                startIcon={<ContentCopy />}
                onClick={() => handleCopyLink('embed')}
                sx={{ mt: 1.5 }}
                color={copySuccess.embed ? "success" : "primary"}
                variant={copySuccess.embed ? "contained" : "outlined"}
              >
                {copySuccess.embed ? "Copied!" : "Copy embed code"}
              </Button>
              
              <Typography variant="caption" display="block" sx={{ mt: 1, color: 'text.secondary' }}>
                Paste this code into your website HTML to embed the form.
              </Typography>
            </Box>
            
            <Divider sx={{ my: 2 }} />
            
            <Typography variant="subtitle2" sx={{ mb: 2, fontWeight: 600 }}>
              Share via
            </Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
              {shareFormId && (
                <>
                  <EmailShareButton 
                    url={getShareUrl()} // Use the same URL function
                    subject={`Please fill out this form: ${shareFormTitle}`}
                    body={`I've shared a form with you that needs to be filled out. Please click this link: ${getShareUrl()}`}
                  >
                    <EmailIcon size={40} round />
                  </EmailShareButton>
                  
                  <FacebookShareButton
                    url={getShareUrl()} // Use the same URL function
                    hashtag="#SurveyForm"
                  >
                    <FacebookIcon size={40} round />
                  </FacebookShareButton>
                  
                  <TwitterShareButton
                    url={getShareUrl()} // Use the same URL function
                    title={`Please fill out this form: ${shareFormTitle}`}
                    hashtags={["SurveyForm", "FormBuilder"]}
                  >
                    <TwitterIcon size={40} round />
                  </TwitterShareButton>
                  
                  <LinkedinShareButton
                    url={getShareUrl()} // Use the same URL function
                    title={`Form: ${shareFormTitle}`}
                    summary={`Please complete this form: ${shareFormTitle}`}
                    source={window.location.origin}
                  >
                    <LinkedinIcon size={40} round />
                  </LinkedinShareButton>
                </>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseShareDialog} variant="outlined">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Theme Selection Dialog */}
        <Dialog
          open={themeDialogOpen}
          onClose={() => setThemeDialogOpen(false)}
          PaperProps={{
            sx: { borderRadius: '12px', maxWidth: 400 }
          }}
        >
          <DialogTitle>
            <Typography variant="h6" fontWeight="600">Customize Theme</Typography>
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel id="theme-select-label">Choose Theme</InputLabel>
              <Select
                labelId="theme-select-label"
                value={themeColor}
                label="Choose Theme"
                onChange={handleThemeChange}
              >
                {themeOptions.map((option) => (
                  <MenuItem key={option.name} value={option.name}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box 
                        sx={{ 
                          width: 24, 
                          height: 24, 
                          borderRadius: '50%', 
                          bgcolor: option.primary,
                          mr: 2
                        }} 
                      />
                      {option.name}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setThemeDialogOpen(false)} variant="outlined">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Reset Response Count Dialog */}
        <Dialog
          open={resetCountDialogOpen}
          onClose={() => setResetCountDialogOpen(false)}
          PaperProps={{
            sx: { borderRadius: '12px', maxWidth: 450 }
          }}
        >
          <DialogTitle>Reset Response Count</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`Do you want to reset the response count for "${selectedFormTitle}"? This action cannot be undone.`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setResetCountDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleResetResponseCount} color="error">
              Reset Count
            </Button>
          </DialogActions>
        </Dialog>

        {/* Submissions History Modal */}
        {/* Remove or comment out the modal code since we'll now use the full page instead */}
        {/* <SubmissionsHistoryModal
          open={submissionsHistoryOpen}
          onClose={handleCloseSubmissionsHistory}
          formTitle={selectedFormTitle}
          formId={selectedFormForReset || ''}
          submissions={submissionsHistory}
          onReset={handleResetResponseCount}
          loading={loadingSubmissions}
          onSubmissionDeleted={() => {
            // Refresh the form list to update counts
            fetchForms();
          }}
          onSubmissionArchiveChanged={() => {
            // Refresh the form list to update counts
            fetchForms();
          }}
          userRole={userRole || undefined}
        /> */}

        {/* Duplicate Form Dialog */}
        <DuplicateFormDialog
          open={duplicateDialogOpen}
          onClose={() => setDuplicateDialogOpen(false)}
          formId={selectedFormForDuplicate}
          formTitle={selectedFormTitle}
          email={selectedFormEmail}
          isAdmin={userRole === "Admin"}
          onSuccess={handleDuplicateSuccess}
        />

        {/* Global Delete Dialog */}
        <Dialog
          open={globalDeleteDialogOpen}
          onClose={() => setGlobalDeleteDialogOpen(false)}
          PaperProps={{
            sx: { borderRadius: '12px', maxWidth: 500 }
          }}
        >
          <DialogTitle sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 1
          }}>
            <Typography variant="h6" fontWeight="600">Delete All Archived Submissions</Typography>
            <IconButton 
              onClick={() => setGlobalDeleteDialogOpen(false)} 
              size="small" 
              aria-label="close"
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ py: 2 }}>
              <Typography color="error.main" variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
                WARNING: This action cannot be undone!
              </Typography>
              <Typography paragraph>
                You are about to permanently delete ALL archived submissions across ALL forms in the system.
              </Typography>
              <Typography paragraph>
                This will completely remove the data from the database and cannot be recovered.
              </Typography>
              <Typography fontWeight="bold">
                Are you absolutely sure you want to proceed?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ p: 2 }}>
            <Button 
              onClick={() => setGlobalDeleteDialogOpen(false)} 
              variant="outlined"
            >
              Cancel
            </Button>
            <Button 
              onClick={handleDeleteAllArchivedGlobally} 
              color="error" 
              variant="contained"
              startIcon={<Delete />}
              disabled={deletingGlobalArchived}
            >
              {deletingGlobalArchived ? 'Deleting...' : 'Delete All Archived'}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default Home;
function removeGhostForm() {
  throw new Error("Function not implemented.");
}
function serverTimestamp() {
  return new Date();
}

