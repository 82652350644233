import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser, useClerk } from '@clerk/clerk-react';
import { doc, getDoc } from 'firebase/firestore';
import { getApprovalFormSummary } from '../services/firebase/approvalService';
import { db } from '../firebase';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Avatar,
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
  Badge
} from '@mui/material';
import {
  DashboardCustomize,
  AccountCircle,
  ExitToApp,
  People,
  Palette,
  Language,
  AssignmentTurnedIn,
  NotificationsNone
} from '@mui/icons-material';

interface SharedNavBarProps {
  onThemeClick?: () => void;
  title?: string;
}

const SharedNavBar: React.FC<SharedNavBarProps> = ({ onThemeClick, title = "Form Manager" }) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { signOut } = useClerk();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [pendingApprovals, setPendingApprovals] = useState<number>(0);

  useEffect(() => {
    const fetchUserRole = async () => {
      if (!user?.id) return;
      try {
        const userRef = doc(db, "users", user.id);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          setUserRole(userSnap.data().role);
        }
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
    };

    fetchUserRole();
  }, [user]);

  useEffect(() => {
    if (!user?.id) return;
    
    // Set a flag to track if the component is still mounted
    let isMounted = true;
    
    const fetchPendingApprovals = async () => {
      // Don't block navigation - use a non-blocking approach
      setTimeout(async () => {
        try {
          if (!isMounted) return;
          
          // Use the approvalService to get form summaries
          const formSummaries = await getApprovalFormSummary(
            user.id,
            userRole === "Admin", // Pass isAdmin flag
            null // No user filtering for the navbar
          );
          
          // Only update state if component is still mounted
          if (isMounted) {
            // Calculate total pending count
            const totalPending = formSummaries.reduce(
              (total, form) => total + form.pendingCount, 
              0
            );
            
            setPendingApprovals(totalPending);
          }
        } catch (error) {
          console.error("Error fetching pending approvals:", error);
          if (isMounted) {
            setPendingApprovals(0);
          }
        }
      }, 0);
    };
    
    fetchPendingApprovals();
    
    // Set up an interval to refresh the counts every 5 minutes
    const intervalId = setInterval(fetchPendingApprovals, 5 * 60 * 1000);
    
    // Cleanup function
    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [user, userRole]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    signOut(() => navigate("/sign-in"));
    handleMenuClose();
  };

  const handleProfileClick = () => {
    navigate('/profile');
    handleMenuClose();
  };

  const handleLandingPageClick = () => {
    navigate('/landing-page-builder');
    handleMenuClose();
  };

  const handleHomeClick = () => {
    console.log("SharedNavBar: handleHomeClick called, navigating to /home");
    navigate('/home');
    console.log("SharedNavBar: navigate('/home') called");
  };

  const handleManageUsers = () => {
    navigate('/manage-users');
    handleMenuClose();
  };

  const handleApprovalManager = () => {
    navigate('/approval-manager');
    handleMenuClose();
  };

  return (
    <AppBar position="sticky">
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton color="inherit" edge="start" onClick={handleHomeClick}>
            <DashboardCustomize sx={{ mr: 1 }} />
          </IconButton>
          <Typography variant="h6" sx={{ fontWeight: 600, letterSpacing: 0.5 }}>
            {title}
          </Typography>
        </Box>

        <Box sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          '@media (max-width: 600px)': {
            display: 'none'
          }
        }}>
          {title !== 'Form Manager' && (
            <Button color="inherit" onClick={handleHomeClick}>
              Home
            </Button>
          )}

          {userRole === "Admin" && (
            <Button
              color="inherit"
              startIcon={<People />}
              onClick={handleManageUsers}
              sx={{ ml: 1 }}
            >
              Manage Users
            </Button>
          )}
          
          {pendingApprovals > 0 && (
            <Button
              color="inherit"
              onClick={handleApprovalManager}
              sx={{ ml: 1, display: { xs: 'none', md: 'flex' } }} // Hide on small screens
              startIcon={
                <Badge badgeContent={pendingApprovals} color="error" max={99}>
                  <AssignmentTurnedIn />
                </Badge>
              }
            >
              Approvals
            </Button>
          )}
        </Box>

        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {pendingApprovals > 0 && (
            <Tooltip title="Form Approvals">
              <IconButton 
                color="inherit" 
                onClick={handleApprovalManager}
                sx={{ display: { xs: 'flex', md: 'none' } }} // Only show on mobile screens
              >
                <Badge badgeContent={pendingApprovals} color="error" max={99}>
                  <AssignmentTurnedIn />
                </Badge>
              </IconButton>
            </Tooltip>
          )}
        
          {onThemeClick && (
            <Tooltip title="Change Theme">
              <IconButton color="inherit" onClick={onThemeClick}>
                <Palette />
              </IconButton>
            </Tooltip>
          )}
          
          {user && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" sx={{ mr: 1.5, display: { xs: 'none', sm: 'block' } }}>
                {user.fullName}
              </Typography>
              <Tooltip title="Account menu">
                <IconButton
                  onClick={handleMenuOpen}
                  size="small"
                  sx={{ border: '2px solid white' }}
                >
                  <Avatar
                    src={user.imageUrl}
                    alt={user.fullName || "User"}
                    sx={{ width: 32, height: 32 }}
                  />
                </IconButton>
              </Tooltip>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                  sx: { width: 200, mt: 1, borderRadius: '12px' }
                }}
              >
                <Box sx={{ p: 2, pb: 1 }}>
                  <Typography variant="subtitle2">{user.fullName}</Typography>
                  <Typography variant="body2" color="text.secondary" fontSize="12px">
                    {user.primaryEmailAddress?.emailAddress}
                  </Typography>
                  {userRole && (
                    <Typography variant="body2" color="primary" fontSize="12px">
                      Role: {userRole}
                    </Typography>
                  )}
                </Box>
                <Divider />
                <MenuItem onClick={handleProfileClick} sx={{ py: 1.5 }}>
                  <ListItemIcon>
                    <AccountCircle fontSize="small" />
                  </ListItemIcon>
                  Update Profile
                </MenuItem>
                <MenuItem onClick={handleLandingPageClick} sx={{ py: 1.5 }}>
                  <ListItemIcon>
                    <Language fontSize="small" />
                  </ListItemIcon>
                  Landing Page
                </MenuItem>
                <MenuItem onClick={handleApprovalManager} sx={{ py: 1.5 }}>
                  <ListItemIcon>
                    {pendingApprovals > 0 ? (
                      <Badge badgeContent={pendingApprovals} color="error" max={99}>
                        <AssignmentTurnedIn fontSize="small" />
                      </Badge>
                    ) : (
                      <AssignmentTurnedIn fontSize="small" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary="Form Approvals"
                    secondary={pendingApprovals > 0 ? `${pendingApprovals} pending` : null}
                  />
                </MenuItem>
                <MenuItem onClick={handleSignOut} sx={{ py: 1.5 }}>
                  <ListItemIcon>
                    <ExitToApp fontSize="small" />
                  </ListItemIcon>
                  Sign Out
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default SharedNavBar;
